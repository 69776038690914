.app-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bs-light);

  &.authenticated::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 15em;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    background: var(--cp-color-accent);
  }
}

.form-label {
  font-weight: bold;
  color: var(--bs-gray-700);
}
