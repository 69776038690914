.topbar {
  background: var(--topbar-background, #e8e5e5) !important;

  .topbar-group {
    &.active {
      position: relative;
      transform: translateY(-0.5em);
      padding: 0.5em 0;
      margin-bottom: -1em;
      z-index: 1;
      clip-path: inset(-100% -100% 0.5em -100%);
    }
  }
}
